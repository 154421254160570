.contactBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 52px;
    width: 414px;
    margin-inline: auto;

    & h2 {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 3.5625rem;
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      margin: 12px 5px 5px;
      font-family: "Poppins";
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      color: #1d1e20;
    }

    input {
      background-color: #ffffff;
      width: 335.07px;
      border: 2px #7ea1c4;
      border-style: none none solid none;
      padding-left: 16px;
      padding-block: 13px;
      font-size: 14px;
      line-height: 21px;
      font-weight: 300;

      &::placeholder {
        color: #7ea1c4;
      }

      &:focus-visible {
        outline: #363636;
        border-color: #363636;
      }
    }
  }

  textarea {
    min-height: 90px;
    background-color: #ffffff;
    border: 2px #7ea1c4;

    border-style: none none solid none;
    padding-left: 20px;
    padding-top: 12px;
    width: 335.07px;

    &::placeholder {
      color: #7ea1c4;
    }

    &:focus-visible {
      outline: #363636;
      border-color: #363636;
    }
  }

  &__button {
    background-color: #7ea1c4;
    border: none;
    color: #ffffff;
    width: 347.07px;
    height: 46px;
    margin-top: 20px;
    margin-bottom: 55px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}

@media screen and (min-width: 1125px) {
  .contactBox {
    flex-direction: row;
    align-items: flex-start;
    max-width: 900px;
    margin-inline: auto;
    margin-top: 180px;
    justify-content: space-between;
  }
  .form {
    margin-top: 0;
    padding: 16px 8px 0px 8px;
    background-color: #FCFCFC;
    border: 1px solid white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    input {
      background-color: #FCFCFC;
    }
    textarea {
      background-color: #FCFCFC;
    }
    &__box {
      padding-top: 0;
    }
    &__button {
      margin-bottom: 30px;
    }
  }
}

::placeholder {
  font-family: "Poppins";
}