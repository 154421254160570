.carousel {
  display: flex;
  justify-content: center;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__heading {
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 52px;
    margin-bottom: 16px;
    margin-top: 74px;
  }

  &__copy {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    margin-block: 0 67px;
  }

  &__bigBox {
    width: 414px;
  }

  &__swiper {
    &--desktop {
      display: none;
    }
  }
}

.card {
  width: 327.46px;
  height: 556.37px;
  background: #7ea1c4;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;

  &__copy {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 40px;
    width: 100%;
  }

  &__text {
    &--top {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
    }

    &--middle {
      font-family: "Moon";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 35px;
      width: 235px;
      text-align: center;
    }

    &--bottom {
      align-self: flex-end;
      margin-right: 28px;
    }
  }
}

@media only screen and (min-width: 1124px) {
  .carousel {
    &__bigBox {
      width: 100vw;
    }

    &__swiper {
      &--mobile {
        display: none;
      }

      &--desktop {
        display: flex;
        width: 100vw;
        overflow: hidden;
      }
    }
  }

  .card {}
}