.contact {
  background-color: #1d1e20;
  width: 100vw;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 113.67px;
  &__bigBox {
    width: 347px;
    margin-left: 33.46px;
    margin-top: 37.66px;
    margin-bottom: 82.47px;
    h2 {
      font-size: 28px;
      font-weight: 700;
    }
  }
  &__infoBox {
    display: flex;
    align-items: center;
    img {
      margin-right: 6px;
    }
  }
  &__emailAddy {
    margin-left: 33px;
  }
  &__number {
    margin-left: 33px;
  }
}

@media screen and (min-width: 1125px) {
  .contact {
    background-color: white;
    color: black;
    margin-top: 0;
    width: fit-content;
    &__bigBox {
      margin: 0;
    }
    h2 {
      margin-top: 0;
    }
  }
}
