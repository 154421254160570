.hero {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__bigBox {
    width: 414px;
    position: relative;
    margin-bottom: 143.5px;
    margin-top: 96px;

    &::after {
      content: "";
      width: 300px;
      height: 2px;
      background: #7ea1c4;
      position: absolute;
      right: 0px;
      bottom: -75px;
      @media only screen and (min-width: 1124px) {
        bottom: 68px;
        left: 23px;
        width: 460px;
      }
    }
  }

  &__gabe {
    display: none;
  }

  &__karina {
    display: none;
  }

  &__bet {
    display: none;
  }

  &__background {
    background-image: url(../../assets/Mobile/mobile_blob.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 414px;
    height: 349px;
  }

  &__motto {
    font-size: 64px;
    padding: 50px 0px 0px 23.9px;
    color: #1d1e20;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__coqui {
    margin: 0px 0px 0px 319px;
  }

  &__text {
    font-size: 17px;
    line-height: 30px;
    margin: 10px 0px 0px 24px;
    color: #1d1e20;
  }

  &__button {
    margin: 25px 0px 0px 24px;
    border-radius: 8px;
    height: 46px;
    width: 158px;
    background: #7ea1c4;
    color: #1d1e20;
    font-size: 16px;
    border: none;
  }
}

@media only screen and (min-width: 1124px) {
  .hero {
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &__bigBox {
      position: relative;
      width: 1085px;
    }

    &__coqui {
      display: none;
    }

    &__background {
      background: none;
      height: 302px;
    }

    &__text {
      width: 458px;
    }

    &__images {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &__karina {
      display: inline;
      width: 685px;
      position: absolute;
      right: 0;
      top: -241px;
    }

    &__gabe {
      display: inline;
      width: 265px;
      position: absolute;
      right: 204px;
      top: 0px;
    }

    &__bet {
      display: inline;
      width: 250px;
      position: absolute;
      right: 0;
      top: -119px;
    }

    &__button {
      margin: 65px 0px 0px 85px;
      width: 189px;
      padding: 10px 29px;
    }
  }
}
