.services {
  background: #1d1e20;
  color: white;
  padding-bottom: 91px;
  li {
    list-style: unset;
  }
  &__header {
    width: 275px;
    margin-inline: auto;
    text-align: center;
    padding-top: 48px;
  }
  &__subheading {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.005em;
    margin-bottom: 0;
  }
  &__heading {
    font-weight: 300;
    font-size: 36px;
    line-height: 52px;
    margin-top: 0px;
  }
  &__copy {
    width: 317px;
    margin-inline: auto;
    margin-bottom: 72px;
  }
  &__bullet {
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
  }
  &__button {
    margin-inline: auto;
    border: none;
  }
  &__phone {
    display: none;
  }
  &__laptop {
    display: none;
  }
}

@media only screen and (min-width: 1124px) {
  .services {
    display: flex;
    justify-content: center;
    align-items: center;
    &__left {
      position: relative;
      width: fit-content;
      margin-right: 85px;
    }
    &__laptop {
      display: inline;
      width: 600px;
    }
    &__phone {
      display: inline;
      width: 275px;
      position: absolute;
      bottom: 0;
      right: -45px;
    }
    &__copy {
      width: 350px;
    }
  }
}
