.principle {
  padding-bottom: 91px;
  &__header {
    width: 275px;
    margin-inline: auto;
    text-align: center;
    padding-top: 48px;
  }
  &__subheading {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.005em;
    margin-bottom: 0;
  }
  &__heading {
    font-weight: 300;
    font-size: 36px;
    line-height: 52px;
    margin-top: 0px;
  }
  &__copy {
    width: 317px;
    margin-inline: auto;
    margin-bottom: 72px;
    li {
      list-style: unset;
    }
  }
  &__bullet {
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
  }
  &__tablet {
    display: none;
  }
  &__phone {
    display: none;
  }
}

@media only screen and (min-width: 1124px) {
  .principle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    margin-bottom: -80px;
    &__header {
      padding-top: 0;
    }
    &__right {
      position: relative;
      width: fit-content;
      margin-left: 70px;
    }
    &__tablet {
      display: inline;
      width: 639px;
    }
    &__phone {
      display: inline;
      width: 275px;
      position: absolute;
      bottom: 55px;
      left: -30px;
    }
  }
}
