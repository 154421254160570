.footer {
  display: flex;
  justify-content: center;
  margin-top: 116px;
  &__bigBox {
    width: 233px;
    img {
      margin-right: 12px;
      margin-bottom: 56px;
      margin-top: 30px;
    }
  }
  &__legal {
    margin-top: 34px;
  }
  &__contactInfo {
    margin-top: 32px;
  }
  &__last {
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1125px) {
  .footer {
    display: flex;
    img {
      margin: 0;
      margin-right: 12px;
    }
    &__mobile {
      display: none;
    }
    &__bigBox {
      width: 900px;
      display: flex;
      justify-content: space-between;
    }
    &__logo {
      width: 255px;
    }
    &__logos {
      display: flex;
      margin-left: 32px;
    }
    h3 {
      margin-block: 0;
    }
    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__legal {
      margin-top: 0px;
    }
    &__contactInfo {
      margin-top: 0px;
    }
    &__last {
      margin-bottom: 0px;
    }
  }
}
