@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

li {
  list-style: none;
}
.header-logo {
  margin-left: 40px;
  margin-right: 450px;
  position: relative;
  display: flex;
}

.coqui-header-logo {
  width: 75px;
}

.navigation {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  width: 100vw;
  height: 88px;
  position: fixed;
  z-index: 99998;
}

a {
  text-decoration: none;
}
.navigation__bigbox {
  width: 1120px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 80px;
  position: fixed;
  z-index: 9999;
  padding-bottom: 8px;
}

.navigation ul {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100vw;
  padding: 0px;
  overflow: hidden;
  z-index: 10;
  left: 0px;
}
.nav-link {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-right: 40px;
  color: #000000;
  &--home {
    display: none;
  }
}
.navigation ul Link {
  list-style-type: none;
  padding-right: 10px;
}
.hamburger {
  display: none;
  z-index: 6;
}
.header-logo-box {
  display: none;
}
@media (max-width: 1124px) {
  .hamburger {
    display: initial;
    padding-top: 10px;
    z-index: 6;
  }

  .header-logo {
    margin-top: 20px;
    margin-right: 105px;
  }

  .navigation ul {
    background-color: #ffffff;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    width: 100vw;
    margin-top: 80px;
    margin-right: 200px;
    position: fixed;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
}

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
}

.nav-item {
  margin-left: 1rem;
}

.nav-logo {
  font-size: 2.1rem;
  font-weight: 500;
  color: #482ff7;
}

@media only screen and (max-width: 1124px) {
  .nav-menu {
    position: fixed;
    top: 5rem;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
  }
  .navigation__bigbox {
    width: 414px;
    display: initial;
  }
  .header-logo-box {
    display: flex;
  }
  .header-logo-1 {
    margin-top: 20px;
    margin-left: 22px;
    margin-right: 176px;
  }
  .header-logo {
    display: none;
  }
  .nav-menu.active {
    left: 0;
  }
  .navigation {
    display: flex;
    justify-content: center;
  }

  .nav-link {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    cursor: pointer;
    margin-right: 1rem;
    margin-top: 1.875rem;
    &--home {
      display: flex;
    }
  }

  .nav-item {
    margin: 2.5rem 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
