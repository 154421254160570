.versus {
  background: #1d1e20;
  color: white;
  padding-bottom: 65px;
  &__header {
    margin-inline: auto;
    text-align: center;
    padding-top: 48px;
    margin-inline: auto;
    width: 400px;
  }
  &__subheading {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.005em;
    margin-bottom: 0;
  }
  &__heading {
    font-weight: 300;
    font-size: 36px;
    line-height: 30px;
    margin-top: 24px;
    height: 150px;
  }
  &__copy {
    width: 275px;
    margin-inline: auto;
    margin-bottom: 69px;
    li {
      list-style: unset;
    }
  }
  &__bullet {
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
  }
  &__button {
    margin-inline: auto;
    border: none;
  }
  &__img {
    display: none;
  }
}

span {
  font-size: 20px;
  display: block;
}

@media only screen and (min-width: 1124px) {
  .versus {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 22px;
    padding-bottom: 0;
    &__left {
      position: relative;
      width: fit-content;
      margin-right: 15px;
      margin-left: 120px;
    }
    &__img {
      display: inline;
      width: 600px;
    }
    &__copy {
      width: 350px;
      margin-bottom: 30px;
    }
    &__heading {
      height: fit-content;
    }
    &__header {
      width: 550px;
    }
  }
  span {
    display: inline;
  }
}
