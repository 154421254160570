.App {
  text-align: center;
}

button {
  display: flex;
  padding: 10px 24px;
  background: #7EA1C4;
  border-radius: 8px;
  color: #1D1E20;
  height: 46px;
  align-items: center;
}

body {
  font-style: normal;
  box-sizing: border-box;
  margin: 0;
}